/*
 * Mr Video 🎬
 *
 * Mr Video is module built for easily customizable and editable video players,
 * able to communicate with other custom elements through events.
 *
 */

import { defineCustomElement, BaseController } from '@mrhenry/wp--custom-elements-helpers';

defineCustomElement( 'mr-video', {
	attributes: [
		{
			attribute: 'controls',
			type: 'boolean',
		},
		{
			attribute: 'has-mobile-assets',
			type: 'bool',
		},
		{
			attribute: 'mobile-video-loop',
			type: 'string',
		},
		{
			attribute: 'video-id',
			type: 'string',
		},
		{
			attribute: 'video-loop',
			type: 'string',
		},
	],
	controller: class extends BaseController {
		init() {
			this.hasPlayableSource = false;

			requestAnimationFrame( () => {
				this.elements = {};
				this.elements.poster = this.el.querySelector( '.js-video-poster' );
				this.elements.video = this.el.querySelector( '.js-video' );

				this.initial = true;

				if ( this.elements.video ) {
					this.checkMuted();
				}

				this.addEventListeners();

				this.setVideoSource();
			} );

		}

		addEventListeners() {
			// mr-video-controls events
			this.on( 'mr-video-controls:togglePlayback', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.togglePlayback();
			}, window );

			this.on( 'mr-video-controls:toggleMute', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.toggleMute();
			}, window );

			this.on( 'mr-video-controls:toggleFullscreen', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				this.toggleFullscreen();
			}, window );

			this.on( 'mr-video-controls:jump', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video || !this.duration || null === e.detail.position ) {
					return;
				}

				this.elements.video.currentTime = this.duration * e.detail.position;
			}, window );

			// mr-info-video events
			this.on( 'mr-info-video:swapped', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId ) {
					return;
				}

				this.controls = true;
				this.swapped = true;
				this.el.setAttribute( 'controls', true );
				this.setup();
			}, window );

			// mr-carousel events
			this.on( 'mr-carousel:startPlayback', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				if ( !this.hasPlayableSource ) {
					return;
				}

				if ( !this.elements.video.paused ) {
					return;
				}

				if ( this.elements.poster && !this.swapped ) {
					this.elements.poster.classList.add( 'is-hidden' );
					this.swapped = true;
				}

				this.togglePlayback();
			}, window );

			this.on( 'mr-carousel:stopPlayback', ( e ) => {
				if ( !this.videoId || this.videoId !== e.detail.videoId || !this.elements.video ) {
					return;
				}

				if ( this.elements.video.paused ) {
					return;
				}

				this.togglePlayback();
			}, window );

			// video events
			this.on( 'canplaythrough', () => {
				if ( this.initial ) {
					this.setup();
					this.initial = false;
				}
			}, this.elements.video );

			this.on( 'transitionend', () => {
				if ( this.elements.poster ) {
					this.el.removeChild( this.elements.poster );
				}
			}, this.elements.poster );

			this.on( 'play', () => {
				this.el.classList.add( 'is-playing' );
				this.dispatch( 'playing' );
			}, this.elements.video );

			this.on( 'pause', () => {
				this.el.classList.remove( 'is-playing' );
				this.dispatch( 'paused' );
			}, this.elements.video );

			this.on( 'volumechange', () => {
				if ( this.elements.video.muted ) {
					this.dispatch( 'muted' );
				} else {
					this.dispatch( 'unmuted' );
				}
			}, this.elements.video );

			this.on( 'timeupdate', () => {
				if ( this.duration && this.elements.video.currentTime ) {
					const currentTimePercentage = this.elements.video.currentTime / this.duration;

					this.el.dispatchEvent( new CustomEvent( 'mr-video:timeUpdate', {
						bubbles: true,
						cancelable: true,
						detail: {
							currentTimePercentage: currentTimePercentage,
							videoId: this.videoId,
						},
					} ) );
				}
			}, this.elements.video );

			// user events
			let moveThrottle = false;

			this.on( 'mouseleave', () => {
				moveThrottle = true;
				this.timeInactivity( 960 );
			}, this.el );

			this.on( 'mouseenter', () => {
				moveThrottle = false;
				this.setFocus();
			}, this.el );

			this.on( 'mousemove', ( e, target ) => {
				if ( !moveThrottle ) {
					if ( this.el.contains( target ) ) {
						moveThrottle = true;
						this.setFocus();
					}

					setTimeout( () => {
						moveThrottle = false;
						this.setFocus();
					}, 240 );
				}
			}, this.el );
		}

		setup() {
			this.duration = this.elements.video.duration;

			if ( isNaN( this.duration ) || 0 === this.duration ) {
				this.tryForDuration( 5 );
			}

			if ( !this.elements.video.hasAttribute( 'autoplay' ) ) {
				return;
			}

			if ( !this.hasPlayableSource ) {
				return;
			}

			if ( this.elements.poster ) {
				this.elements.poster.classList.add( 'is-hidden' );
			}

			if ( this.swapped && this.elements.video.paused ) {
				this.togglePlayback();
			}

			this.checkMuted();

			this.initial = false;
			this.dispatch( 'showControls' );
			this.timeInactivity( 2000 );
		}

		tryForDuration( max = 5 ) {
			let tries = 0;

			this.checkInterval = setInterval( () => {
				tries += 1;

				if ( !isNaN( this.elements.video.duration ) && 0 < this.elements.video.duration ) {
					this.duration = this.elements.video.duration;
					clearInterval( this.checkInterval );
				}

				if ( tries === max ) {
					clearInterval( this.checkInterval );
				}
			}, 960 );
		}

		setFocus() {
			clearTimeout( this.unfocusTimer );
			this.dispatch( 'focussed' );
			this.timeInactivity( 1280 );
		}

		timeInactivity( time ) {
			clearTimeout( this.unfocusTimer );

			this.unfocusTimer = setTimeout( () => {
				this.dispatch( 'unfocussed' );
			}, time );
		}

		togglePlayback() {
			if ( this.elements.video.paused ) {
				this.elements.video.play();
			} else {
				this.elements.video.pause();
			}
		}

		toggleMute() {
			if ( this.elements.video.muted ) {
				this.elements.video.muted = false;
			} else {
				this.elements.video.muted = true;
			}
		}

		toggleFullscreen() {
			if ( this.elements.video.requestFullscreen ) {
				this.elements.video.requestFullscreen();

			} else if ( this.elements.video.msRequestFullscreen ) {
				this.elements.video.msRequestFullscreen();

			} else if ( this.elements.video.mozRequestFullScreen ) {
				this.elements.video.mozRequestFullScreen();

			} else if ( this.elements.video.webkitRequestFullscreen ) {
				this.elements.video.webkitRequestFullscreen();

			} else if ( this.elements.video.webkitEnterFullscreen ) {
				this.elements.video.webkitEnterFullscreen();

			} else {
				console.warn( '[mr-video] element doesn\'t support fullscreen' );
			}
		}

		setVideoSource() {
			if ( this.elements.video ) {
				// Set the teaser src
				if ( 768 < window.innerWidth ) {
					// Add the video src of desktop
					if ( this.videoLoop ) {
						this.elements.video.src = this.videoLoop;
						this.hasPlayableSource = true;
					}
				} else if ( this.hasMobileAssets && this.mobileVideoLoop !== undefined ) { // Check if Mobile Video is added
					// Add the video src of mobile
					this.elements.video.src = this.mobileVideoLoop;
					this.hasPlayableSource = true;
				} else if ( this.videoLoop ) {
					// Add the video src of desktop
					this.elements.video.src = this.videoLoop;
					this.hasPlayableSource = true;
				}
			}
		}

		dispatch( event ) {
			if ( this.videoId && this.controls ) {
				this.el.dispatchEvent( new CustomEvent( `mr-video:${event}`, {
					bubbles: true,
					cancelable: true,
					detail: {
						videoId: this.videoId,
					},
				} ) );
			}
		}

		checkMuted() {
			const muted = this.elements.video.getAttribute( 'muted' );

			if ( false !== muted ) {
				this.dispatch( 'muted' );
			}
		}
	},
} );
